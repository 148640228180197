<template>
  <b-modal
    ref="modal-create-group"
    hide-title
    ok-title="Zavrieť"
    size="lg"
    ok-only
    @ok="handleOk"
    @show="resetGroup()"
  >
    <template #modal-header="{ close }">
      <h5>{{ $t("class-creation") }}</h5>
      <svg-cross-icon @click="close()" />
    </template>
    <div class="m-5">
      <div class="buttons-wrapper">
        <button
          @click="onClickWithoutContinuation()"
          class="without-continuation"
        >
          <radiobutton v-if="!selectedWithoutClass" />
          <radiobuttonSelected v-else />
          {{ $t("create-new-class") }}
        </button>
        <button @click="onClickWithContinuation()" class="with-continuation">
          <radiobutton v-if="!selectedWithClass" />
          <radiobuttonSelected v-else />
          {{ $t("continue-class") }}
        </button>
      </div>
      <div v-if="selectedWithClass" class="copy-class">
        <InputSelect
          class="select-year"
          v-model="selectedAcademicYear"
          :options="academicYears"
          :optionLabel="optionLabel"
          :placeholder="$t('choose-year')"
          :label="$t('choose-year-to-continue')"
        />
        <InputSelect
          class="select-class"
          v-model="selectedGroup"
          :options="groups"
          :optionLabel="optionLabel"
          :placeholder="$t('choose-class')"
          :label="$t('choose-particular-class')"
        />
      </div>
      <form
        v-if="selectedWithClass || selectedWithoutClass"
        @submit="handleSubmit"
      >
        <b-form-group invalid-feedback="Emaily su povinne">
          <p class="p-above-input w-color-black">{{ $t("class-name") }}</p>
          <b-form-input
            v-model="form.name"
            class="mb-4"
            placeholder="napr. 1.A, Chrumkáčikovia"
          >
          </b-form-input>
          <p class="p-above-input w-color-black">{{ $t("subject") }}</p>
          <b-form-input
            v-model="form.subject"
            placeholder="napr. Biológia, Matematika"
          >
          </b-form-input>
        </b-form-group>
      </form>
      <div v-if="selectedWithClass || selectedWithoutClass" class="yellow-box">
        <div class="d-flex mx-auto box-content mt-3">
          <svg-light-bulb-icon class="mr-5" />
          <p class="modal-box-text font-weight-bold">
            {{ $t("recomend-class-name") }}
          </p>
        </div>
      </div>
    </div>
    <template #modal-footer="{ ok }">
      <b-button
        class="modal-btn"
        variant="primary"
        @click="ok()"
        :disabled="!selectedWithClass && !selectedWithoutClass"
      >
        {{ $t("create") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  components: {
    "svg-light-bulb-icon": () => import("/assets/icons/lightBulb.svg?inline"),
    "svg-cross-icon": () => import("/assets/icons/cross.svg?inline"),
    InputSelect: () => import("/components/InputSelect.vue"),
    radiobutton: () => import("/assets/icons/radioButton.svg?inline"),
    radiobuttonSelected: () =>
      import("/assets/icons/radioButtonSelected.svg?inline"),
  },

  props: {
    academicYears: {
      required: true,
      type: Array,
    },
    getGroups: {
      required: true,
    },
  },

  data() {
    return {
      form: {
        name: "",
        subject: "",
      },
      groups: [],
      selectedAcademicYear: null,
      selectedGroup: null,
      selectedWithClass: false,
      selectedWithoutClass: false,
    };
  },

  watch: {
    selectedAcademicYear: async function (newValue, oldValue) {
      if (!newValue) {
        this.selectedAcademicYear = this.academicYears[0];
        return;
      }

      this.selectedAcademicYear = newValue;
      this._loadGroups();
    },
  },

  methods: {
    showModal() {
      this._load();
      this.$nextTick(() => {
        this.$refs["modal-create-group"].show();
      });
    },
    resetSelect() {
      this.selectedAcademicYear = this.academicYears[0];
      this.selectedGroup = null;
    },
    resetGroup() {
      this.form = {
        name: "",
        subject: "",
      };
      this.resetSelect();
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.form.name || !this.form.subject) return;

      this.$emit("createGroup", this.form, this.selectedGroup);

      this.$nextTick(() => {
        this.$refs["modal-create-group"].hide();
      });
    },
    optionLabel(option) {
      return option.attributes.name;
    },
    async _loadGroups() {
      this.groups = await this.getGroups(this.selectedAcademicYear.id);
    },
    _load() {
      this.selectedAcademicYear = this.academicYears[0];
      this._loadGroups();
    },
    onClickWithoutContinuation() {
      if (!this.selectedWithoutClass) {
        this.selectedWithClass = false;
      }
      this.selectedWithoutClass = !this.selectedWithoutClass;
    },
    onClickWithContinuation() {
      if (!this.selectedWithClass) {
        this.selectedWithoutClass = false;
      }
      this.selectedWithClass = !this.selectedWithClass;
    },
  },
};
</script>

<style lang="scss" scoped>
.yellow-box {
  width: 100%;
  background: #fff5d1;
  border: 1px solid #e6d8a7;
  box-sizing: border-box;
  align-items: center;
  border-radius: 0.25rem;

  .box-content {
    width: 95%;
  }
}

.modal-box-text {
  color: #cab979;
}

.buttons-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;

  margin-bottom: 1.5rem;

  button {
    background: none;
    border: none;
    padding: 0;
  }
}

svg {
  cursor: pointer;
}

input {
  border: none;
  border-bottom: 1px solid #cadeea;
  width: 100%;
  &::placeholder {
    color: rgba(156, 161, 174, 0.4);
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.6875rem;
  }
  &:focus {
    outline: 0;
  }
  &:read-only {
    background-color: transparent;
  }
}

::v-deep .modal-content {
  top: 9.375rem;
}

.p-above-input {
  font-size: 0.875rem;
  line-height: 1.3125rem;
}

::v-deep .modal {
  .modal-dialog {
    .modal-content {
      top: 9.375rem;
      .modal-header {
        border-bottom: 1px solid #e2edf3;
        button {
          color: #2b2e4a;
        }
        border-bottom: 0 none;
        .modal-title {
          font-family: "Boing";
          font-size: 1.5rem;
          line-height: 1.625rem;
        }
      }
      .modal-btn {
        color: white;
      }
      .p-above-input {
        color: #2b2e4a;
        font-size: 0.875rem;
        line-height: 1.3125rem;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 1.125rem;
      }
      b {
        font-weight: 700;
      }
      input {
        color: #2b2e4a;
        font-weight: 700;
      }
      border-style: solid;
      border-width: 1px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-image: linear-gradient(90.01deg, #0064ff 60.75%, #fb2564 79.89%) 0
        0 100% 0/0 0 4.53px 0 stretch;
    }
    .modal-footer {
      button {
        width: 13.8125;
        height: 3.375;
      }
      border-top: 0 !important;
    }
  }
}
</style>
